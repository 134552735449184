import '../style/main.scss';
import 'bootstrap';
import $ from 'jquery';
import './jquery.overlayScrollbars'
import 'slick-slider';
import 'readmore-js';
import 'jquery-ui-dist/jquery-ui.js';
import 'malihu-custom-scrollbar-plugin';
//import 'magnific-popup'
import Flickity from 'flickity';

$(document).ready(function ($) {

  let difet;
  // menu modal
  $(".open-menu").click(function () {
    $('.menu-modal').toggleClass('menu-modal__closed')
  });
  $(".menu-modal__close .icon-close").click(function () {
    $('.menu-modal').toggleClass('menu-modal__closed')
  });

  if ($("#risultato_ricerca").lenght) {
    $("html,body").animate({
      scrollTop: $("#risultato_ricerca").offset().top
    }, 2000);
  }

  /* LV 30/09/2021 */

  function SetCookie(c_name, value, expiredays) {
    var exdate = new Date()
    exdate.setDate(exdate.getDate() + expiredays)
    document.cookie = c_name + "=" + escape(value) + ";path=/" + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString())
  }

  if (document.cookie.indexOf("eucookie") === -1) {
    $('#eucookielaw').fadeIn(500);
  }

  $("#removecookie").on('click', function () {
    //console.log("click");
    SetCookie('eucookie', 'eucookie', 365 * 1)
    $("#eucookielaw").remove();
  });
  $("body").on('click', 'a', function () {
    SetCookie('eucookie', 'eucookie', 365 * 1)
    $("#eucookielaw").remove();
  });

  //menu mobile
  $(".menu-mobile").click(function () {
    $('.header-main__main-mobile').toggleClass('header-main__main-mobile--closed')
  });
  $(".header-main__main-mobile--close").click(function () {
    $('.menu-item').removeClass('open');
    $('.header-main__main-mobile').toggleClass('header-main__main-mobile--closed');
  });

  $(".header-main__main-mobile--menu > .menu-item").click(function () {
    if ($(this).find('ul').hasClass('sub-menu')) {
      $(this).toggleClass('open');
    }

  });

  $(window).resize(function () {
    $('.header-main__main-mobile').addClass('header-main__main-mobile--closed')
  });
  //menu filter
  $(".level-1").click(function () {
    if ($(this).hasClass("open-filter")) {
      $(this).toggleClass('open-filter');
    } else {
      $(".level-1").removeClass('open-filter');
      $(this).toggleClass('open-filter');
    }
  });

  $(".filter-select").click(function () {
    $(this).toggleClass('active-filter');
  });

  $(".menu-hbg__open-menu").click(function () {
    $('.menu-filter ').toggleClass('menu-filter-active');
    $('.menu-filter-mobile ').addClass('d-block');
  });
  //second menu dropdown visiblity
  $(".second-menu-hmb ").click(function () {
    $(".second-menu-hmb-dropdown").toggleClass('active');
  });
  $(".menu-hbg__close-menu").click(function () {
    $(".level-1").removeClass('open-filter');
    $(".menu-filter").removeClass('menu-filter-active');
    $('.menu-filter-mobile ').removeClass('d-block');
  });

  if ($(window).width() < 992) {
    $('#scrool-menu').addClass('scrool_menu');
  } else {
    $('#scrool-menu').removeClass('scrool_menu');
  }
  if ($(window).width() < 1400) {
    $("#scrool-menu").mCustomScrollbar({
      axis: "y",
      theme: "dark-3",
    });
  }

  $('.carousel-prd').slick({
    infinite: false,
    arrows: true,
    prevArrow: "<span class='slick-prev pull-left icon-prev'></span>",
    nextArrow: "<span class='slick-next pull-right icon-next'></span>"
  });
  $('.color-pav').slick({
    infinite: false,
    arrows: true,
    prevArrow: "<span class='slick-prev pull-left icon-prev'></span>",
    nextArrow: "<span class='slick-next pull-right icon-next'></span>"
  });

  var $gallerypr = $(".model-17");
  $(document).ready(function () {
    $gallerypr.find('.prd-slider').slick({
      arrows: true,
      centerMode: true,
      prevArrow: $('.nav-prev'),
      nextArrow: $('.nav-next'),

      responsive: [{
        breakpoint: 991,
        settings: {
          centerMode: true,
        }
      }]
    });

    if ($('div').hasClass('prd-slider')) {
      changeimg('.prd-slider');
      changeFinishing();
      $('.prd-slider').on("afterChange", function () {
        changeimg('.prd-slider');
        changeFinishing();
      })
    }
  });


  var $gallery = $(".model-00");
  var slideCount = null;
  $(document).ready(function () {
    $gallery.find('.home-slider').slick({
      arrows: true,
      centerMode: true,
      prevArrow: $('.nav-prev'),
      nextArrow: $('.nav-next'),

      responsive: [{
        breakpoint: 991,
        settings: {
          centerMode: true,
        }
      }]
    });

    if ($('div').hasClass('model-00')) {
      changeimg_bg();
      changeimg('.home-slider');
      $('.home-slider').on("afterChange", function () {
        changeimg('.home-slider');
        changeimg_bg();
      })
    }
  });

  $gallery.on("init", function (event, slick) {
    slideCount = slick.slideCount;
    setSlideCount();
    setCurrentSlideNumber(slick.currentSlide);
  });

  $gallery.on("beforeChange", function (event, slick, currentSlide, nextSlide) {
    setCurrentSlideNumber(nextSlide);
  });

  function setSlideCount() {
    var $el = $(".total").text(slideCount);
  }

  function setCurrentSlideNumber(currentSlide) {
    var $el = $(".current").text(currentSlide + 1);
  }

  var $galleryPrd = $(".gallery-prod");
  $(document).ready(function () {
    $galleryPrd.slick({
      dots: true,
      infinite: true,
      variableWidth: true,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }]
    });

    $galleryPrd.find('.slick-slide').on('click', function () {
      var getimage = $(this).find('.carousel-cell-image');
      var linkimage = getimage.attr('src');
      $('.modal-zoom').addClass('active-zoom hoverable-close');
      $('.modal-zoom').append('<div class="image-parallax "><img class="drag-image" src="' + linkimage + '"></div>');
      // dragimage('#img-zoom', '#drag-image')


      // ************************ move gallery image zoom  ************************

      $('.image-parallax').mousemove(function (e) {

        var offset = $(this).offset();
        var xPos = e.pageX - offset.left;
        var yPos = e.pageY - offset.top;
        var mouseXPercent = Math.round(xPos / $(this).width() * 100);
        var mouseYPercent = Math.round(yPos / $(this).height() * 100);

        $(this).children('img').each(
          function () {
            var diffX = $('.image-parallax').width() - $(this).width();
            var diffY = $('.image-parallax').height() - $(this).height();
            var myX = diffX * (mouseXPercent / 100);
            var myY = diffY * (mouseYPercent / 100);
            var cssObj = {
              'left': myX + 'px',
              'top': myY + 'px'
            }
            TweenMax.to(this, 1, {left: myX, top: myY}, {duration: 50, queue: false, easing: 'linear'})
          }
        );
      });

      $(".modal-zoom .image-parallax").click(function () {
        $('.modal-zoom').empty();
        $('.modal-zoom').removeClass('active-zoom hoverable-close');
      });
      // ************************ end move gallery image zoom  ************************

    });


  });


  function changeFinishing() {
    var finitura = $('.slick-current .product-item__item').attr('data-finitura');
    $('.model-17 .nav-slider .nav-text').text(finitura);
  }

  function changeimg_bg() {
    if ($(window).width() > 991) {
      var imageBG = $('.slick-current .product-item__item').attr('data-bg');
      $('.model-00__bg-image').fadeTo('slow', 0, function () {
        $(this).css('background-image', 'url(' + imageBG + ')');
      }).delay(100).fadeTo('slow', 1);

    }
  }

  function changeimg(classe) {
    if ($(window).width() < 992) {
      var link = $(classe + ' .slick-current .images').attr('src');
      $('.image-prd').empty();
      // $('.image-prd').append('<img class="images" src="' + link + '">');
      $('#img-mobile').empty();
      $('#img-mobile').append('<img class="embed-responsive" src="' + link + '">');
    } else {
      var link = $(classe + ' .slick-current .images').attr('src');
      $('.image-prd').empty();
      // $('.image-prd').append('<img class="images" src="' + link + '">');
      $('#img-mobile').empty();
      $('#img-mobile').append('<img class="embed-responsive" src="' + link + '">');
    }
  }


  $(".specifiche-menu .item span").click(function () {
    var cont = $(this).attr('data-container');
    $(".specifiche-menu .item").removeClass('specifiche-active');
    $(this).parent('li').addClass('specifiche-active');
    $('.specifiche-content .item').removeClass('specifiche-active');
    $('.specifiche-content #' + cont).addClass('specifiche-active');
  });

  //********************************* GALLEY *********************


  $(".model-19__more").click(function () {
    var h = $(window).height() - 100;
    $('.model-19').toggleClass('read-more');
    if ($(window).width() < 992) {
      $('.model-19__center--text').fadeToggle(900);
    }

    $('.model-19__more > span').toggleClass('icon-more-text icon-close');

  });

  $(".model-45__tittle").click(function () {
    $('.model-45').toggleClass('form-open');
  });

  $('a[href = "#interested"]').click(function () {
    $('.model-45').addClass('form-open');
  });


  $('.model-14-carousel').slick({
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }]

  });

  $('.carousel__itens').slick({
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  });


  $('.model-04__itens').slick({
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  });

  $('#slick-BDA-top').slick({
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  });

  $('#slick-BDA-gallery').slick({
    dots: false,
    infinite: false,
    speed: 300,
    arrows: true,
    autoplay: true,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: true,
    prevArrow: "<span class='slick-prev pull-left icon-prev'></span>",
    nextArrow: "<span class='slick-next pull-right icon-next'></span>"
  });


  function equalizerCol(eqcontent, eqitem) {
    $(eqcontent).each(function () {

      // Cache the highest
      var highestBox = 0;

      // Select and loop the elements you want to equalise
      $(eqitem, this).each(function () {

        // If this box is higher than the cached highest then store it
        if ($(this).height() > highestBox) {
          highestBox = $(this).height();
        }

      });

      // Set the height of all those children to whichever was highest
      $(eqitem, this).height(highestBox);

    });
  };


  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          // $('html, body').scrollLeft(target.offset().left);

          if ($('.header-main').hasClass('diesel-page')) {

            $('html, body').animate({
              scrollLeft: target.offset().left
            }, 1000, function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) { // Checking if the target was focused
                return false;
              } else {
                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
              ;
            });

          } else {
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000, function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) { // Checking if the target was focused
                return false;
              } else {
                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
              ;
            });
          }

        }
      }
    });


  $('.model-45 form input[type="button"]').click(function () {
    $('.model-45 form input').each(function () {
      if ($(this).val() == "") {
        $(this).next('.input-error').removeClass('d-none')
      } else {
        $(this).next('.input-error').addClass('d-none')
      }
    });
    $('form textarea').each(function () {
      if ($(this).val() == "") {
        $(this).next('.input-error').removeClass('d-none')
      } else {
        $(this).next('.input-error').addClass('d-none')
      }
    });

  });
  $('.model-06__cta').click(function () {
    $('.model-06 form input').each(function () {
      if ($(this).val() == "") {
        $(this).next('.input-error').removeClass('d-none')
      } else {
        $(this).next('.input-error').addClass('d-none')
      }
    });
  });


  if ($(window).width() < 768) {
    $('.model-47__text-content').readmore({
      speed: 75,
      collapsedHeight: 195,
      moreLink: '<div class="d-block readmore"><a href="#">Leggi di più</a></div>',
      lessLink: '<div class="d-block readmore"><a href="#">Chiudi</a></div>'
    });

    $('.model-49__text-content--small').readmore({
      speed: 75,
      collapsedHeight: 150,
      moreLink: '<div class="d-block readmore"><a href="#">Leggi di più</a></div>',
      lessLink: '<div class="d-block readmore"><a href="#">Chiudi</a></div>'
    });
  }


  //************************ select language in footer **************************

  var x, i, j, l, ll, selElmnt, a, b, c;
  /*look for any elements with the class "custom-footer__select":*/
  x = document.getElementsByClassName("footer__select");
  l = x.length;
  for (i = 0; i < l; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];
    ll = selElmnt.length;

    /*for each element, create a new DIV that will act as the selected item:*/

    var d;
    for (d = 0; d < ll; d++) {
      // console.log(selElmnt.options[d]);
      var domain = window.location.hostname;
      // console.log(domain);
      // console.log(domain + " - " + selElmnt.options[d].value);
      if ("http://" + domain == selElmnt.options[d].value || "https://" + domain == selElmnt.options[d].value) {
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[d].innerHTML;
        a.targetUrl = selElmnt.options[d].value;
        //console.log(selElmnt.options[d].value);
        x[i].appendChild(a);
        break;
      } else {
        // console.log(domain);
        if (domain == "0.0.0.0") {
          a = document.createElement("DIV");
          a.setAttribute("class", "select-selected");
          a.innerHTML = selElmnt.options[d].innerHTML;
          a.targetUrl = selElmnt.options[d].value;
          //console.log(selElmnt.options[d].value);
          x[i].appendChild(a);

        }
      }
    }

    //console.log(d);
    /*for each element, create a new DIV that will contain the option list:*/
    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");
    //b.setAttribute("data-target", a.targetUrl);
    for (j = 0; j < ll; j++) {
      /*for each option in the original select element,
      create a new DIV that will act as an option item:*/
      c = document.createElement("DIV");
      c.innerHTML = selElmnt.options[j].innerHTML;
      c.targetUrl = selElmnt.options[j].value;
      //console.log(c.targetUrl);
      c.setAttribute("data-target", c.targetUrl);
      c.addEventListener("click", function (e) {

        //console.log($(this).data("target"));
        window.location = $(this).data("target");
        /*when an item is clicked, update the original select box,
        and the selected item:*/
        /* var y, i, k, s, h, sl, yl;
         s = this.parentNode.parentNode.getElementsByTagName("select")[0];
         sl = s.length;
         h = this.parentNode.previousSibling;
         for (i = 0; i < sl; i++) {
           if (s.options[i].innerHTML == this.innerHTML) {
             s.selectedIndex = i;
             h.innerHTML = this.innerHTML;
             y = this.parentNode.getElementsByClassName("same-as-selected");
             yl = y.length;
             for (k = 0; k < yl; k++) {
               y[k].removeAttribute("class");
             }
             this.setAttribute("class", "same-as-selected");
             break;
           }
         }*/
        h.click();
      });
      b.appendChild(c);
    }
    x[i].appendChild(b);
    if (a) {
      a.addEventListener("click", function (e) {
        /*when the select box is clicked, close any other select boxes,
        and open/close the current select box:*/
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");
      });
    }
  }

  function closeAllSelect(elmnt) {
    /*a function that will close all select boxes in the document,
    except the current select box:*/
    var x, y, i, xl, yl, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    xl = x.length;
    yl = y.length;
    for (i = 0; i < yl; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i)
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }

  /*if the user clicks anywhere outside the select box,
  then close all select boxes:*/
  document.addEventListener("click", closeAllSelect);

  //************************ end select language in footer **************************


  // ************************ page Diesel Living ***************************************

  // for page horizintal

  if ($('.header-main').hasClass('diesel-page')) {

    let controller = new ScrollMagic.Controller({vertical: false});

    $('body').css({'overflow-x': 'auto', 'overflow-y': 'hidden'});
    $('html').css({'overflow-x': 'auto', 'overflow-y': 'hidden'});
    $('.header-main').removeClass('bg-white');
    $('.header-main__shadow-boder').remove();
    setTimeout(
      function () {
        $('.scroll-back-01').remove();
      }, 3000);

    $('.menu-vert-open').click(function () {
      $('.menu-vert').addClass('open-vert');
    });

    $('.menu-vert-close').click(function () {
      $('.menu-vert').removeClass('open-vert');
    });
    $('.icon-back-home').click(function () {
      $('.menu-vert').removeClass('open-vert');
    });


    var section01 = [{
      "toggle": ".scroll-back-01",
      "element": "scroll-back-01",
      "triggerHook": "onEnter",
      "class": "visible"
    },
      {
        "toggle": ".header-main",
        "element": "header-main",
        "triggerHook": "onEnter",
        "class": "visible"
      },
      {
        "toggle": ".back-image-02",
        "element": "back-image-02",
        "triggerHook": "onEnter",
        "class": "visible"
      },
      {
        "toggle": ".back-image-03",
        "element": "back-image-03",
        "triggerHook": "onEnter",
        "class": "visible"
      },
      {
        "toggle": ".back-image-04",
        "element": "back-image-04",
        "triggerHook": "onEnter",
        "class": "visible"
      },
      {
        "toggle": ".content-text",
        "element": "content-text",
        "triggerHook": "onEnter",
        "class": "visible"
      }

    ];

    if ($(window).width() > 991) {
      for (var i = 0; i < section01.length; i++) {
        new ScrollMagic.Scene({
          triggerElement: section01['toggle'],
          triggerHook: section01[i]['triggerHook'],
          reverse: false,
          duration: "5000%",
        })
          .setClassToggle(section01[i]['toggle'], section01[i]['class'])
          .addTo(controller);
      }
    }
    //----------------------------------------------------------------------------------
    if ($(window).width() > 991) {
      $('.parallax').each(function () {
        var trig = this.parentNode,
          parallax = this.getAttribute('data-parallax'),
          parallaxIni = this.getAttribute('data-ini');
        if (parallaxIni == null) {
          parallaxIni = 0
        }
        new ScrollMagic.Scene({
          triggerElement: trig,
          triggerHook: parallaxIni,
          duration: "100%"
        })
          .setTween(this, {x: parallax, ease: Linear.easeNone})
          .addTo(controller);
      })
    }

    //----------------------------------------------------------------------------------
    var section02 = [{
      'toggle': '#logo-diesel',
      'from': {'autoAlpha': 0},
      'to': {'autoAlpha': 1, 'ease': Linear.easeNone},
      'start': .85,
      'duration': 300
    },
      {
        'toggle': '#text-1',
        'from': {'autoAlpha': 0},
        'to': {'autoAlpha': 1, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400
      },
      {
        'toggle': '#text-2',
        'from': {'autoAlpha': 0, bottom: -30},
        'to': {'autoAlpha': 1, bottom: 0, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 300
      },
      {
        'toggle': '#text-3',
        'from': {'autoAlpha': 0, bottom: -30},
        'to': {'autoAlpha': 1, bottom: 0, 'ease': Linear.easeNone},
        'start': 0.7,
        'duration': 300
      },
      {
        'toggle': '#text-bar',
        'from': {'width': 0},
        'to': {'width': 220, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 300
      },
      {
        'toggle': '.three__imgs--image-02',
        'from': {'left': 0, 'autoAlpha': 0},
        'to': {'left': -60, 'autoAlpha': 1, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 300
      },
      {
        'toggle': '.three__imgs--image-03',
        'from': {'right': -177, 'autoAlpha': 0},
        'to': {'right': -117, 'autoAlpha': 1, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 300
      },
      {
        'toggle': '.three__imgs--image-02',
        'from': {'left': -60},
        'to': {'left': -200, 'ease': Linear.easeNone},
        'start': 0.2,
        'duration': 500
      },
      {
        'toggle': '.three__imgs--image-03',
        'from': {'right': -117},
        'to': {'right': 0, 'ease': Linear.easeNone},
        'start': 0.2,
        'duration': 500
      },
      {
        'toggle': '.four__imgs--image-02',
        'from': {'right': 0, 'autoAlpha': 0},
        'to': {'right': -118, 'autoAlpha': 1, 'ease': Linear.easeNone},
        'start': 0.6,
        'duration': 400
      },
      {
        'toggle': '.four__imgs--image-04',
        'from': {'left': 0, 'autoAlpha': 0},
        'to': {'left': -117, 'autoAlpha': 1, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400
      },
      {
        'toggle': '.five__imgs--image-02',
        'from': {'right': 0, 'autoAlpha': 0},
        'to': {'right': -118, 'autoAlpha': 1, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400
      },
      {
        'toggle': '.five__col2',
        'from': {'bottom': 200, 'autoAlpha': 0},
        'to': {'bottom': 100, 'autoAlpha': 1, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 300
      },
      {
        'toggle': '.six__imgs',
        'from': {'left': -200, 'autoAlpha': 0},
        'to': {'left': 0, 'autoAlpha': 1, 'ease': Linear.easeNone},
        'start': 0.7,
        'duration': 400
      },
      {
        'toggle': '.six__text',
        'from': {'left': 200, 'autoAlpha': 0},
        'to': {'left': 0, 'autoAlpha': 1, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 200
      },
    ];

    if ($(window).width() > 991) {
      for (var i = 0; i < section02.length; i++) {
        var tween = new TimelineMax()
          .add([
            TweenMax.fromTo(section02[i]['toggle'], 3, section02[i]['from'], section02[i]['to']),
          ]);

        new ScrollMagic.Scene({
          triggerElement: section02[i]['toggle'],
          triggerHook: section02[i]['start'],
          duration: section02[i]['duration'],
          reverse: false
        })
          .setTween(tween)
          .addTo(controller);

      }

      var section03 = [
        // {
        //   'toggle': '.six__col1',
        //   'from': {'left': -200, 'autoAlpha': 0},
        //   'to': {'left': 250, 'autoAlpha': 1, 'ease': Linear.easeNone},
        //   'start': 0.1,
        //   'duration': '50%'
        // }
      ];

      for (var i = 0; i < section03.length; i++) {
        var tween = new TimelineMax()
          .add([
            TweenMax.fromTo(section03[i]['toggle'], 3, section03[i]['from'], section03[i]['to']),
          ]);

        new ScrollMagic.Scene({
          triggerElement: section03[i]['toggle'],
          triggerHook: section03[i]['start'],
          duration: section03[i]['duration'],
          reverse: true
        })
          .setTween(tween)
          .addTo(controller);

      }
    }
    //----------------------------------------------------------------------------------

    var section03 = [

      {
        "toggle": "#animation-01.seven__content--item",
        "element": "#animation-01",
        "triggerHook": 0.5,
        "class": "visible"
      },
      {
        "toggle": "#animation-02.seven__content--item",
        "element": "#animation-02",
        "triggerHook": 0.5,
        "class": "visible"
      },
      {
        "toggle": "#animation-03.seven__content--item",
        "element": "#animation-03",
        "triggerHook": 0.7,
        "class": "visible"
      }
    ]
    if ($(window).width() > 991) {
      for (var i = 0; i < section03.length; i++) {
        new ScrollMagic.Scene({
          triggerElement: section03[i]['toggle'],
          triggerHook: section03[i]['triggerHook'],
          duration: section03[i]['duration'],
          reverse: false
        })
          .setClassToggle(section03[i]['toggle'], section03[i]['class'])
          .addTo(controller);
      }
    }
    //----------------------------------------------------------------------------------

    var section04 = [{
      'triggerElement': '.six__text',
      'toggle': '.six__text',
      'start': 0.6,
      'duration': 400,
      'offset': 0
    }

    ];
    if ($(window).width() > 991) {
      for (var i = 0; i < section04.length; i++) {
        new ScrollMagic.Scene({
          triggerElement: section04[i]['triggerElement'],
          triggerHook: section04[i]['triggerHook'],
          duration: section04[i]['duration'],
          offset: section04[i]['offset'],
          reverse: true
        })
          .setPin(section04[i]['toggle'])
          .addTo(controller);

      }


      //----------------------------------------------------------------------------------


      var scene = new ScrollMagic.Scene({
        triggerElement: '#page3',
        triggerHook: 0.9,
        duration: '100%'
      })
        .setClassToggle('#menu-vert-styck', 'open-vert')
        .addTo(controller);

      var scene = new ScrollMagic.Scene({
        triggerElement: '#page3',
        triggerHook: 0,
      })
        .setClassToggle('#menu-vert-styck', 'styck')
        .addTo(controller);

    } else {

      var scene = new ScrollMagic.Scene({
        triggerElement: '#page3',
        triggerHook: 0,
        offset: 0
      })
        .setClassToggle('#menu-vert-styck', 'styck')
        .addTo(controller);

    }


    $(".menu-vert ul").on("click", "a", function () {
      $('.menu-vert').removeClass('open-vert');
    });


    (function () {
      function scrollHorizontally(e) {
        e = window.event || e;
        var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
        document.documentElement.scrollLeft -= (delta * 40); // Multiplied by 40
        document.body.scrollLeft -= (delta * 40); // Multiplied by 40
        // e.preventDefault();
      }

      if (window.addEventListener) {
        window.addEventListener("mousewheel", scrollHorizontally, false);
        window.addEventListener("DOMMouseScroll", scrollHorizontally, false);
      } else {
        window.attachEvent("onmousewheel", scrollHorizontally);
      }
    })();


    // ************************ page Diesel Living  end ***********************************
  } else {

    // ********************** animation in  page normal ***********************************


    let controller = new ScrollMagic.Controller();


    setTimeout(function () {
      // homepage

      var hfooter = $('footer').offset();
      var aniMove = [{
        "toggle": ".header-main",
        "triggerHook": "onEnter",
        "class": "visible"
      },
        {
          "toggle": ".hide-backend",
          "triggerHook": "onEnter",
          "class": "visible"
        },
        {
          "toggle": ".image",
          "triggerHook": "onEnter",
          "class": "visible"
        },
        {
          "toggle": ".texts .hat",
          "triggerHook": "onEnter",
          "class": "visible"
        },
        {
          "toggle": ".texts .title",
          "triggerHook": "onEnter",
          "class": "visible"
        },
        {
          "toggle": ".texts .subtitle",
          "triggerHook": "onEnter",
          "class": "visible"
        },
        {
          "toggle": ".texts .paragrafo",
          "triggerHook": "onEnter",
          "class": "visible"
        },
        {
          "toggle": ".texts .cta",
          "triggerHook": "onEnter",
          "class": "visible"
        },
        {
          "toggle": ".nav-slider",
          "triggerHook": "onEnter",
          "class": "visible"
        }
      ]

      // listing-progetti
      aniMove.push({
          "toggle": ".model-19__image-bg",
          "triggerHook": "onEnter",
          "class": "visible"
        }, {
          "toggle": ".model-21",
          "triggerHook": "onEnter",
          "class": "visible"
        }, {
          "toggle": ".model-18__content div",
          "triggerHook": "onEnter",
          "class": "visible"
        }, {
          "toggle": ".model-18__bg",
          "triggerHook": "onEnter",
          "class": "visible"
        }, {
          "toggle": ".model-18__details",
          "triggerHook": "onEnter",
          "class": "visible"
        }, {
          "toggle": ".model-18__breadcrumb",
          "triggerHook": "onEnter",
          "class": "visible"
        },
        // {
        //   "toggle": ".animation-footer",
        //   "triggerHook": 0,
        //   "class": "visible",
        //   // "offset": hfooter.top - 700
        // }
      );

      if ($(window).width() > 991) {
        for (var i = 0; i < aniMove.length; i++) {

          new ScrollMagic.Scene({
            triggerElement: aniMove['toggle'],
            triggerHook: aniMove[i]['triggerHook'],
            reverse: true,
            duration: aniMove[i]['duration'],
            offset: aniMove[i]['offset']
          })
            .setClassToggle(aniMove[i]['toggle'], aniMove[i]['class'])
            .addTo(controller);
        }
      }
    }, 300);

    setTimeout(function () {

      // homepage
      var aniContinuo = [{
        'toggle': '.model-01__title',
        'from': {'opacity': 0},
        'to': {'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.9,
        'duration': 200,
        'offset': 0
      },
        {
          'toggle': '.model-01__title',
          'from': {'bottom': '-200px'},
          'to': {'bottom': 0, 'ease': Linear.easeNone},
          'start': 1,
          'duration': '100%',
          'offset': 0
        },
        {
          'toggle': '.model-01__animation-01',
          'from': {'bottom': '-200px', 'opacity': 0},
          'to': {'bottom': 0, 'opacity': 1, 'ease': Linear.easeNone},
          'start': 0.8,
          'duration': 400,
          'offset': 0
        },
        {
          'toggle': '.model-01__animation-02',
          'from': {'opacity': 0},
          'to': {'opacity': 1, 'ease': Linear.easeNone},
          'start': 0.8,
          'duration': 200,
          'offset': 0
        },
        {
          'toggle': '.model-01__animation-02',
          'from': {'bottom': '-300px'},
          'to': {'bottom': 0, 'ease': Linear.easeNone},
          'start': 0.6,
          'duration': '100%',
          'offset': 0
        },
        {
          'toggle': '.model-01__cta',
          'from': {'opacity': 0},
          'to': {'opacity': 1, 'ease': Linear.easeNone},
          'start': 0.75,
          'duration': 200,
          'offset': 0
        },
        {
          'toggle': '.model-01__cta',
          'from': {'bottom': '100px'},
          'to': {'bottom': '-80px', 'ease': Linear.easeNone},
          'start': 0.8,
          'duration': '100%',
          'offset': 0
        },
        {
          'toggle': '.model-02__img-01',
          'from': {'opacity': 0},
          'to': {'opacity': 1, 'ease': Linear.easeNone},
          'start': 0.7,
          'duration': 200,
          'offset': 0
        },
        {
          'toggle': '.model-02__img-01',
          'from': {'top': '100px'},
          'to': {'top': '-50px', 'ease': Linear.easeNone},
          'start': 0.7,
          'duration': '130%',
          'offset': 0
        },
        {
          'toggle': '.model-02__center',
          'from': {'top': '100px', 'opacity': 0},
          'to': {'top': 0, 'opacity': 1, 'ease': Linear.easeNone},
          'start': 0.7,
          'duration': 400,
          'offset': 0
        },
        {
          'toggle': '.model-02__img-02',
          'from': {'opacity': 0},
          'to': {'opacity': 1, 'ease': Linear.easeNone},
          'start': 0.7,
          'duration': 200,
          'offset': 0
        },
        {
          'toggle': '.model-02__img-02',
          'from': {'top': '400px'},
          'to': {'top': '190px', 'ease': Linear.easeNone},
          'start': 0.7,
          'duration': '100%',
          'offset': 0
        },
        {
          'toggle': '.model-02__img-03',
          'from': {'opacity': 0},
          'to': {'opacity': 1, 'ease': Linear.easeNone},
          'start': 1,
          'duration': 400,
          'offset': 0
        },
        {
          'toggle': '.model-02__img-03',
          'from': {'bottom': 0},
          'to': {'bottom': '300px', 'ease': Linear.easeNone},
          'start': 1,
          'duration': '140%',
          'offset': 0
        },
        {
          'toggle': '.model-02__cente-hor',
          'from': {'opacity': 0},
          'to': {'opacity': 1, 'ease': Linear.easeNone},
          'start': 0.7,
          'duration': 400,
          'offset': 0
        },
        {
          'toggle': '.model-02__cente-hor',
          'from': {'top': '50%'},
          'to': {'top': '60%', 'ease': Linear.easeNone},
          'start': 0.7,
          'duration': '100%',
          'offset': 0
        },
        {
          'toggle': '.model-03__imgs',
          'from': {'right': '-150px', 'opacity': 0},
          'to': {'right': '0', 'opacity': 1, 'ease': Linear.easeNone},
          'start': 0.7,
          'duration': 400,
          'offset': 0
        },
        {
          'toggle': '.model-03__center',
          'from': {'opacity': 0},
          'to': {'opacity': 1, 'ease': Linear.easeNone},
          'start': 0.7,
          'duration': 400,
          'offset': 0
        },
        {
          'toggle': '.model-04__animation-01',
          'from': {'opacity': 0},
          'to': {'opacity': 1, 'ease': Linear.easeNone},
          'start': 0.7,
          'duration': 400,
          'offset': 0
        },
        {
          'toggle': '.model-04 .slick-list',
          'from': {'right': '-150px', 'opacity': 0},
          'to': {'right': '0', 'opacity': 1, 'ease': Linear.easeNone},
          'start': 0.8,
          'duration': 200,
          'offset': 0
        },
        {
          'toggle': '.model-04__itens',
          'from': {'right': '-150px', 'opacity': 0},
          'to': {'right': '0', 'opacity': 1, 'ease': Linear.easeNone},
          'start': 0.7,
          'duration': 200,
          'offset': 0
        },
        {
          'toggle': '.model-04__bg',
          'from': {'right': '-150px', 'opacity': 0},
          'to': {'right': '0', 'opacity': 1, 'ease': Linear.easeNone},
          'start': 0.5,
          'duration': 200,
          'offset': 0
        },
        {
          'toggle': '.model-05__img-01',
          'from': {'opacity': 0},
          'to': {'opacity': 1, 'ease': Linear.easeNone},
          'start': 0.5,
          'duration': 200,
          'offset': 0
        },
        {
          'toggle': '.model-05__news',
          'from': {'opacity': 0},
          'to': {'opacity': 1, 'ease': Linear.easeNone},
          'start': 0.9,
          'duration': 100,
          'offset': 0
        },
        {
          'toggle': '.model-05__news',
          'from': {'top': 0},
          'to': {'top': '-200px', 'ease': Linear.easeNone},
          'start': 0.6,
          'duration': '100%',
          'offset': 0
        },
        {
          'toggle': '.model-05__text',
          'from': {'opacity': 0},
          'to': {'opacity': 1, 'ease': Linear.easeNone},
          'start': 0.8,
          'duration': 200,
          'offset': 0
        },
        {
          'toggle': '.model-05__box',
          'from': {'top': '-140px'},
          'to': {'top': '-280px', 'ease': Linear.easeNone},
          'start': 0.8,
          'duration': '100%',
          'offset': 0
        },
        {
          'toggle': '.model-05__cta',
          'from': {'top': '140px'},
          'to': {'top': '280px', 'ease': Linear.easeNone},
          'start': 0.95,
          'duration': '100%',
          'offset': 0
        },
        {
          'toggle': '.model-06',
          'from': {'opacity': 0},
          'to': {'opacity': 1, 'ease': Linear.easeNone},
          'start': 0.8,
          'duration': 200,
          'offset': 0
        }
      ]


      var hmonitor = $(window).height();
      var hanimation = 0.6;
      if (hmonitor > 900) {
        hanimation = 0.85
      }
      // listing-progetti
      aniContinuo.push({
        'toggle': '.model-19__image-bg',
        'from': {'top': 0},
        'to': {'top': '-30%', 'ease': Linear.easeNone},
        'start': .1,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-33__anitation',
        'from': {'top': '200px', 'opacity': 0},
        'to': {'top': 0, 'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-34',
        'from': {'opacity': 0},
        'to': {'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.6,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-36__animation-01',
        'from': {'opacity': 0},
        'to': {'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-36__animation-02',
        'from': {'opacity': 0},
        'to': {'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.7,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-35',
        'from': {'opacity': 0},
        'to': {'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.7,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-37__animation-01',
        'from': {'opacity': 0},
        'to': {'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.7,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-37__animation-02',
        'from': {'opacity': 0},
        'to': {'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-38__animation-01',
        'from': {'opacity': 0, 'left': '-150px'},
        'to': {'opacity': 1, 'left': 0, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-38__animation-02',
        'from': {'opacity': 0, 'right': '-150px'},
        'to': {'opacity': 1, 'right': 0, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-39__animation-01',
        'from': {'opacity': 0, 'top': '-150px'},
        'to': {'opacity': 1, 'top': 0, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-39__animation-02',
        'from': {'opacity': 0, 'top': '150px'},
        'to': {'opacity': 1, 'top': 0, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-11 .gallery-prod',
        'from': {'opacity': 0, 'top': '150px'},
        'to': {'opacity': 1, 'top': 0, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-32 .specifiche-menu ',
        'from': {'opacity': 0, 'top': '-50px'},
        'to': {'opacity': 1, 'top': 0, 'ease': Linear.easeNone},
        'start': 0.6,
        'duration': 200,
        'offset': 0
      }, {
        'toggle': '.model-32 .specifiche-content',
        'from': {'opacity': 0, 'top': '150px'},
        'to': {'opacity': 1, 'top': 0, 'ease': Linear.easeNone},
        'start': 0.7,
        'duration': 200,
        'offset': 0
      }, {
        'toggle': '.animation-41',
        'from': {'opacity': 0},
        'to': {'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.6,
        'duration': 200,
        'offset': 0
      }, {
        'toggle': '.model-14__title',
        'from': {'opacity': 0, 'top': '100px'},
        'to': {'opacity': 1, 'top': 0, 'ease': Linear.easeNone},
        'start': 0.6,
        'duration': 150,
        'offset': 0
      }, {
        'toggle': '.model-14 .slick-track',
        'from': {'opacity': 0, 'top': '100px'},
        'to': {'opacity': 1, 'top': 0, 'ease': Linear.easeNone},
        'start': 0.6,
        'duration': 200,
        'offset': 0
      }, {
        'toggle': '.model-25__ani-01',
        'from': {'opacity': 0, 'top': '135px'},
        'to': {'opacity': 1, 'top': 0, 'ease': Linear.easeNone},
        'start': 0.6,
        'duration': 200,
        'offset': 0
      }, {
        'toggle': '.model-25__two-img--margin',
        'from': {'opacity': 0, 'top': '-135px'},
        'to': {'opacity': 1, 'top': 0, 'ease': Linear.easeNone},
        'start': 0.6,
        'duration': 200,
        'offset': 0
      }, {
        'toggle': '.model-47__text-content',
        'from': {'opacity': 0, 'scale': 1.5},
        'to': {'opacity': 1, 'scale': 1, 'ease': Linear.easeNone},
        'start': 0.6,
        'duration': 200,
        'offset': 0
      }, {
        'toggle': '.model-48__ani',
        'from': {'opacity': 0, 'scale': 1.5},
        'to': {'opacity': 1, 'scale': 1, 'ease': Linear.easeNone},
        'start': 0.6,
        'duration': 300,
        'offset': 0
      }, {
        'toggle': '.model-49 > div',
        'from': {'opacity': 0, 'scale': 1.5},
        'to': {'opacity': 1, 'scale': 1, 'ease': Linear.easeNone},
        'start': 0.6,
        'duration': 300,
        'offset': 0
      }, {
        'toggle': '.model-10__text',
        'from': {'opacity': 0},
        'to': {'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.6,
        'duration': 300,
        'offset': 0
      }, {
        'toggle': '.model-09__ani-01',
        'from': {'opacity': 0, 'left': '-100px'},
        'to': {'opacity': 1, 'left': 0, 'ease': Linear.easeNone},
        'start': 0.6,
        'duration': 300,
        'offset': 0
      }, {
        'toggle': '.model-09__img-02',
        'from': {'opacity': 0, 'right': '-100px'},
        'to': {'opacity': 1, 'right': 0, 'ease': Linear.easeNone},
        'start': 0.6,
        'duration': 300,
        'offset': 0
      }, {
        'toggle': '.model-13__video-playing',
        'from': {'opacity': 0, 'scale': 0.5},
        'to': {'opacity': 1, 'scale': 1, 'ease': Linear.easeNone},
        'start': 0.6,
        'duration': 300,
        'offset': 0
      }, {
        'toggle': '.animation-footer',
        'from': {'top': 0},
        'to': {'top': -800, 'ease': Linear.easeNone},
        'start': hanimation,
        'duration': 300,
        'offset': -0.6
      }, {
        'toggle': '.model-60__animation-01',
        'from': {'opacity': 0, 'right': '-150px'},
        'to': {'opacity': 1, 'right': 0, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 300,
        'offset': 0
      }, {
        'toggle': '.model-60__animation-02',
        'from': {'opacity': 0, 'top': '150px'},
        'to': {'opacity': 1, 'top': 0, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-63__img-1',
        'from': {'top': '100px', 'opacity': 0},
        'to': {'top': 0, 'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.7,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-63__text-1',
        'from': {'opacity': 0},
        'to': {'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.5,
        'duration': 300,
        'offset': 0
      }, {
        'toggle': '.model-63__img-1',
        'from': {'top': '100px', 'opacity': 0},
        'to': {'top': 0, 'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.7,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-63__text-1',
        'from': {'opacity': 0},
        'to': {'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.5,
        'duration': 300,
        'offset': 0
      }, {
        'toggle': '.model-63__img-2',
        'from': {'top': '100px', 'opacity': 0},
        'to': {'top': 0, 'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.7,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-63__text-2',
        'from': {'opacity': 0},
        'to': {'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.5,
        'duration': 300,
        'offset': 0
      }, {
        'toggle': '.model-63__img-3',
        'from': {'top': '100px', 'opacity': 0},
        'to': {'top': 0, 'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.7,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-63__text-3',
        'from': {'opacity': 0},
        'to': {'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.5,
        'duration': 300,
        'offset': 0
      }, {
        'toggle': '.model-63__img-4',
        'from': {'top': '100px', 'opacity': 0},
        'to': {'top': 0, 'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.7,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-63__text-4',
        'from': {'opacity': 0},
        'to': {'opacity': 1, 'ease': Linear.easeNone},
        'start': 0.5,
        'duration': 300,
        'offset': 0
      }, {
        'toggle': '.model-64__animation-11',
        'from': {'opacity': 0, 'right': '-150px'},
        'to': {'opacity': 1, 'right': 0, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-64__animation-21',
        'from': {'opacity': 0, 'left': '-150px'},
        'to': {'opacity': 1, 'left': 0, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-64__animation-31',
        'from': {'opacity': 0, 'bottom': '-150px'},
        'to': {'opacity': 1, 'bottom': 0, 'ease': Linear.easeNone},
        'start': 0.99,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-64__animation-12',
        'from': {'opacity': 0, 'right': '-150px'},
        'to': {'opacity': 1, 'right': 0, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-64__animation-22',
        'from': {'opacity': 0, 'left': '-150px'},
        'to': {'opacity': 1, 'left': 0, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-64__animation-32',
        'from': {'opacity': 0, 'bottom': '-150px'},
        'to': {'opacity': 1, 'bottom': 0, 'ease': Linear.easeNone},
        'start': 0.99,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-64__animation-13',
        'from': {'opacity': 0, 'right': '-150px'},
        'to': {'opacity': 1, 'right': 0, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-64__animation-23',
        'from': {'opacity': 0, 'left': '-150px'},
        'to': {'opacity': 1, 'left': 0, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-64__animation-33',
        'from': {'opacity': 0, 'bottom': '-150px'},
        'to': {'opacity': 1, 'bottom': 0, 'ease': Linear.easeNone},
        'start': 0.99,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-64__animation-14',
        'from': {'opacity': 0, 'right': '-150px'},
        'to': {'opacity': 1, 'right': 0, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-64__animation-24',
        'from': {'opacity': 0, 'left': '-150px'},
        'to': {'opacity': 1, 'left': 0, 'ease': Linear.easeNone},
        'start': 0.8,
        'duration': 400,
        'offset': 0
      }, {
        'toggle': '.model-64__animation-34',
        'from': {'opacity': 0, 'bottom': '-150px'},
        'to': {'opacity': 1, 'bottom': 0, 'ease': Linear.easeNone},
        'start': 0.99,
        'duration': 400,
        'offset': 0
      },);

      if ($(window).width() > 991) {

        for (var i = 0; i < aniContinuo.length; i++) {
          var tween = new TimelineMax()
            .add([
              TweenMax.fromTo(aniContinuo[i]['toggle'], 50, aniContinuo[i]['from'], aniContinuo[i]['to']),
            ]);

          new ScrollMagic.Scene({
            triggerElement: aniContinuo[i]['toggle'],
            triggerHook: aniContinuo[i]['start'],
            duration: aniContinuo[i]['duration'],
            offset: aniContinuo[i]['offset'],
            reverse: true
          })
            .setTween(tween)
            .addTo(controller);

        }
      }
    }, 300);


    // *********** home page top animation *****************************************************


    // *********** end home page top animation *****************************************************

    // ********************** end animation in  page normal ***********************************
  }

  //*****************************  Model Video model-13 ******************************************
  if ($('div').hasClass('model-13')) {

    setTimeout(function () {
      onYouTubeIframeAPIReady();
    }, 500);

    function onYouTubeIframeAPIReady() {
      $('.stop-video').hide();
      var idvideo = $('#yt-video').attr('data-IDvideo')

      window.player = new YT.Player("yt-video", {
        width: 600,
        height: 400,
        videoId: idvideo,
        showinfo: 0,
        controls: 0,
        playerVars: {
          color: "white",
          fs: 0,
          rel: 0,
          'controls': 0,
          'showinfo': 0
        },
        events: {
          'onReady': initialize
        }
      });
    }

    function initialize() {
      updateTimerDisplay();
      updateProgressBar();
      clearInterval(time_update_interval);
      var time_update_interval = setInterval(function () {
        updateTimerDisplay();
        updateProgressBar();
      }, 1000)
    }

    var currentTime = document.getElementById('current-time');
    var duration = document.getElementById('duration');
    var progressBar = document.getElementById('progress-bar');
    var playPause = document.getElementById('play-pause');
    var muteBtn = document.getElementById('mute-btn');
    var volumeInput = document.getElementById('volume-input');
    var playPauseSwitch = true; // true = Play!
    var muteSwitch = true; // true = Mute!

    function updateTimerDisplay() {
      currentTime.innerHTML = formatTime(player.getCurrentTime());
      duration.innerHTML = formatTime(player.getDuration());
    }

    function updateProgressBar() {
      progressBar.value = (player.getCurrentTime() / player.getDuration()) * 100;
    }

    progressBar.addEventListener('mouseup', function (e) {
      var newTime = player.getDuration() * (e.target.value / 100);
      player.seekTo(newTime);
    });

    playPause.addEventListener('click', function (e) {
      if (playPauseSwitch) {
        player.playVideo();
        $('.start-video').hide();
        $('#thumbnail_container').fadeOut('slow');
        $('.stop-video').show();

      } else {
        player.pauseVideo();
        $('.start-video').show();
        $('.stop-video').hide();
      }
      playPauseSwitch = !playPauseSwitch;
    });

    function formatTime(time) {
      time = Math.round(time);
      var minutes = Math.floor(time / 60),
        seconds = time - minutes * 60;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      return minutes + ":" + seconds;
    }


  }
  //***************************** End Model Video model-13 ******************************************


  //***************************** menu stick  ******************************************
  function removeResult() {
    if ($('#filter-result').hasClass('result')) {
      $('#filter-result').css('opacity', '0')
    }
  }

  function addResult() {
    if ($('#filter-result').hasClass('result')) {
      $('#filter-result').css('opacity', '1')
    }
  }

  function checkResult() {
    var offset = $('.pointresult').offset();
    var scrollTop = offset.top;

    if (scrollTop < difet) {
      $('#filter-result').css('opacity', '1')
    } else {
      $('#filter-result').css('opacity', '0')
    }
    difet = scrollTop;
  }

  //***************************** end menu stick  ******************************************


  // ************************ cursor custom link ************************

  // if ($(window).width() > 991) {
  //     const $bigBall = document.querySelector('.cursor__ball--big');
  //     const $hoverables = document.querySelectorAll('.hoverable');
  //     const $hoverablesLink = document.querySelectorAll('.hoverable-link');
  //     const $hoverablesZoom = document.querySelectorAll('.hoverable-zoom');
  //     const $hoverablesClose = document.querySelectorAll('.hoverable-close');

  //     // Listeners
  //     for (let i = 0; i < $hoverables.length; i++) {
  //         $hoverables[i].addEventListener('mouseenter', onMouseHoverScroll);
  //         $hoverables[i].addEventListener('mouseleave', onMouseHoverOut);
  //     }
  //     for (let i = 0; i < $hoverablesLink.length; i++) {
  //         $hoverablesLink[i].addEventListener('mouseenter', onMouseHoverLink);
  //         $hoverablesLink[i].addEventListener('mouseleave', onMouseHoverOut);
  //     }
  //     for (let i = 0; i < $hoverablesZoom.length; i++) {
  //         $hoverablesZoom[i].addEventListener('mouseenter', onMouseHoverZoom);
  //         $hoverablesZoom[i].addEventListener('mouseleave', onMouseHoverOut);
  //     }
  //     for (let i = 0; i < $hoverablesClose.length; i++) {
  //         $hoverablesClose[i].addEventListener('mouseenter', onMouseHoverClose);
  //         $hoverablesClose[i].addEventListener('mouseleave', onMouseHoverOut);
  //     }

  //     // Hover an element

  //     function onMouseHoverScroll() {
  //         $('.cursor__ball--big').removeClass('scroll-icon link-icon zoom-icon close-icon');
  //         $('.cursor__ball--big').addClass('scroll-icon');
  //         TweenMax.to($bigBall, .3, {
  //             scale: 1,
  //             display: 'block'
  //         })
  //     }

  //     function onMouseHoverLink() {
  //         $('.cursor__ball--big').removeClass('scroll-icon link-icon zoom-icon close-icon');
  //         $('.cursor__ball--big').addClass('link-icon');
  //         TweenMax.to($bigBall, .3, {
  //             scale: 1,
  //             display: 'block'
  //         })
  //     }

  //     function onMouseHoverZoom() {
  //         $('.cursor__ball--big').removeClass('scroll-icon link-icon zoom-icon close-icon');
  //         $('.cursor__ball--big').addClass('zoom-icon');
  //         TweenMax.to($bigBall, .3, {
  //             scale: 1,
  //             display: 'block'
  //         })
  //     }

  //     function onMouseHoverClose() {
  //         $('.cursor__ball--big').removeClass('scroll-icon link-icon zoom-icon close-icon');
  //         $('.cursor__ball--big').addClass('close-icon');
  //         TweenMax.to($bigBall, .3, {
  //             scale: 1,
  //             display: 'block'
  //         })
  //     }

  //     function onMouseHoverOut() {
  //         TweenMax.to($bigBall, .3, {
  //             scale: 0
  //         })
  //     }

  //     // Move the cursor
  //     $(document).on("mousemove", function(event) {
  //         var difH = $(window).scrollTop();
  //         var difX = $(window).scrollLeft();
  //         TweenMax.to($bigBall, .4, {
  //             x: event.pageX - 15 - difX,
  //             y: event.pageY - 15 - difH
  //         })
  //     });

  // }
  // ************************ end cursor custom link ************************

  // ************************ store locator & google maps ************************


  if ($('div').hasClass('model-56')) {

    // const storesJson = require('../json/stores.php');
    var markers = [];
    var markerCluster;
    var filteredMarkers;

    $(".sub-menu-scroll").overlayScrollbars({
      className: "os-theme-light",
      autoUpdate: true,
    });


    function initMap() {
      var map = new google.maps.Map(document.getElementById('map'), {
        zoom: 3,
        center: new google.maps.LatLng(39, -7),
        styles: [{
          "elementType": "geometry",
          "stylers": [{
            "color": "#f5f5f5"
          }]
        },
          {
            "elementType": "labels.icon",
            "stylers": [{
              "visibility": "off"
            }]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#616161"
            }]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [{
              "color": "#f5f5f5"
            }]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#bdbdbd"
            }]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [{
              "color": "#eeeeee"
            }]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#757575"
            }]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [{
              "color": "#e5e5e5"
            }]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#9e9e9e"
            }]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [{
              "color": "#ffffff"
            }]
          },
          {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#757575"
            }]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [{
              "color": "#dadada"
            }]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#616161"
            }]
          },
          {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#9e9e9e"
            }]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [{
              "color": "#e5e5e5"
            }]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [{
              "color": "#eeeeee"
            }]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [{
              "color": "#c9c9c9"
            }]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#9e9e9e"
            }]
          }
        ],
      });
      // This is needed to set the zoom after fitbounds,
      google.maps.event.addListener(map, 'zoom_changed', function () {
        var zoomChangeBoundsListener =
          google.maps.event.addListener(map, 'bounds_changed', function (event) {
            if (this.getZoom() > 15) {
              // Change max/min zoom here
              this.setZoom(15);
            }
            google.maps.event.removeListener(zoomChangeBoundsListener);
          });
      });
      initMarkers(map);
      initCluser(map, markers);
      // show how many stores we have in total
      $("#stores-counter").text(markers.length);
      window.map = map;
    }

    function initMarkers(map) {
      $.getJSON('/assets/json/stores.php', function (data) {
        for (var i = 0; i < data.length; i++) {
          setMarker(data[i], map);
        }
      });
    }


    function initCluser(map, markers) {
      markerCluster = new MarkerClusterer(map, markers, {
        ignoreHidden: true,
        styles: [{
          height: 50,
          url: "/assets/icons/cluster.png",
          width: 50,
          textColor: '#FFF',
        }],
      });
    }

    function setMarker(markerData, map) {

      var coordinates = markerData.geometry.coordinates;
      var pos = new google.maps.LatLng(coordinates[1], coordinates[0]);
      var typology = markerData.typology;
      var markerIcon;
      if (typology) {
        if (typology === "flagship") {
          markerIcon = "/assets/icons/marker_flagship.png";
        } else if (typology === "contact") {
          markerIcon = "/assets/icons/marker_flagship_white.png";
        }
      } else {
        markerIcon = "/assets/icons/marker.png";
      }

      var marker = new google.maps.Marker({
        position: pos,
        preTitle: markerData.preTitle,
        title: markerData.title,
        typology: markerData.typology,
        geoState: markerData.geoState,
        geoRegion: markerData.geoRegion,
        phone: markerData.phone,
        maplink: markerData.maplink,
        site: markerData.site,
        address: markerData.address,
        workHours: markerData.workHours,
        description: markerData.description,
        contctLink: markerData.contctLink,
        fax: markerData.fax,
        email: markerData.email,
        map: map,
        visibleSidebar: true,
        icon: markerIcon
      });
      marker.setVisible(false);
      markers.push(marker);
      var infowindow = new google.maps.InfoWindow({
        content: getMarkerHtml(marker)
      });

      // Marker click listener
      google.maps.event.addListener(marker, 'click', (function (marker1, markerData) {
        return function () {
          infowindow.setContent(markerData);
          infowindow.open(map, marker);
          map.panTo(this.getPosition());
        }
      })(marker, markerData));
    }


    var filterMarkers = function (filterKey, filterValue) {
      var bounds = new google.maps.LatLngBounds();
      for (i = 0; i < markers.length; i++) {
        var marker = markers[i];
        marker.filtered = false;
        marker.visibleSidebar = true;
        if (marker[filterKey] == filterValue || filterValue.length == 0) {
          marker.setVisible(true);
          bounds.extend(marker.getPosition());
        } else {
          marker.setVisible(false);
        }
        map.fitBounds(bounds);
      }
      return markers;
    }


    // MENU AREA GEOGRAFICA
    $(".filter-map .filter-select-single").click(function () {
      $(".filter-select-single").removeClass('active-filter');
      $(".nation-filter").removeClass('active');
      $(this).toggleClass('active-filter');
      $(this).parents('li').addClass("active");
      $(this).parents('li').find('span[data-area="title"]').text($(this).text());
      $(".nation-filter").show("blind", 400);
      populateNations($(this).find("a").data("map-area"));
    });

    // MENU NAZIONE/REGIONE
    $('body').on('click', '.filter-map .nation-filter .filter-select-single', function () {
      if (!$(this).hasClass("active-filter")) {
        $(".nation-filter  .filter-select-single").removeClass('active-filter');
        $(this).toggleClass('active-filter');
        $(this).parents('li').addClass("active");
        filteredMarkers = filterMarkers("geoState", $(this).find("a").data("map-nation"));
        drawSidebar(filteredMarkers);
        $(".map-search-input").val("");
        $(".seach-filter").show("blind", 400);
        $("#sidebar").addClass("active");
      }
    });


    function getMarkerHtml(marker) {
      var contentBox = '';
      if (marker.typology && marker.typology.toLowerCase() === "contact") {
        if (marker.preTitle) {
          contentBox += '<span class="box__typology">' + marker.preTitle + '</span>';
        }
        if (marker.title) {
          contentBox += '<span class="box__title">' + marker.title + '</span>';
        }
        if (marker.description) {
          contentBox += '<span class="box__description">' + marker.description + '</span>';
        }
        if (marker.contctLink) {
          contentBox += '<span class="box__action-link"><a href="' + marker.contctLink + '" target="_blank">Contattaci</a></span>';
        }
      } else {
        var parts = marker.address.split('|');
        if (marker.preTitle) {
          contentBox += '<span class="box__preTitle">' + marker.preTitle + '</span>';
        }
        if (marker.title) {
          contentBox += '<span class="box__title">' + marker.title + '</span>';
        }
        if (parts[0]) {
          contentBox += '<span class="box__address">' + parts[0];
          if (parts[1]) {
            contentBox += '<br><span class="box__address">' + parts[1] + '</span>';
          } else {
            contentBox += '</span>';
          }
        }
        if (marker.phone) {
          contentBox += '<span class="box__phone">Tel. <a href="ph:' + marker.phone + '">' + marker.phone + '</a></span>';
        }
        if (marker.fax) {
          contentBox += '<span class="box__fax">Fax. <a href="ph:' + marker.fax + '">' + marker.fax + '</a></span>';
        }
        var workHoursParts = marker.workHours.split('|');
        if (workHoursParts[0]) {
          contentBox += '<span class="box__workHours">' + workHoursParts[0];
          if (workHoursParts[1]) {
            contentBox += '<br><span class="box__workHours">' + workHoursParts[1] + '</span>';
          } else {
            contentBox += '</span>';
          }
        }
        // if (marker.workHours) {
        //   contentBox += '<span class="box__workHours">' + marker.workHours + '</span>';
        // }
        if (marker.site || marker.email || marker.maplink) {
          contentBox += '<div class="box__icons-map">';
        }
        if (marker.site) {
          contentBox += '<a href="' + marker.site + '" target="_blank"><span class="icon-website"></span> </a>';
        }
        if (marker.email) {
          contentBox += '<a href="mailto:' + marker.email + '" target="_blank"><span class="icon-mail"></span> </a>';
        }
        if (marker.maplink) {
          contentBox += '<a href="' + marker.maplink + '" target="_blank"><span class="icon-pin"></span> </a>';
        }
        if (marker.site || marker.email || marker.maplink) {
          contentBox += '</div>';
        }
      }
      return contentBox;
    }

    function drawSidebar(markers) {
      var html = "";
      for (var i = 0; i < markers.length; i++) {
        if (markers[i].visible && markers[i].visibleSidebar) {
          if (markers[i].typology && markers[i].typology.toLowerCase() === "flagship") {
            html += "<li id=\"store-" + i + "\" class='flagship'>" + getMarkerHtml(markers[i]) + "</li>";
          } else if (markers[i].typology && markers[i].typology.toLowerCase() === "contact") {
            html += "<li id=\"store-" + i + "\" class='contact'>" + getMarkerHtml(markers[i]) + "</li>";
          } else {
            html += "<li id=\"store-" + i + "\">" + getMarkerHtml(markers[i]) + "</li>";
          }

        }
      }
      $('.list-stores').empty().html(html);
    }

    function populateNations(area) {
      var html = "";
      var nations = [];
      for (var i = 0; i < markers.length; i++) {
        if (markers[i].geoRegion === area && !nations.includes(markers[i].geoState)) {
          nations.push(markers[i].geoState);
          html += "<li class=\"filter-select-single\"><a href=\"javascript:void(0);\" data-map-area=\"" + markers[i].geoRegion + "\" data-map-nation=\"" + markers[i].geoState + "\">" + markers[i].geoState + "</a></li>";
        }
      }
      $("#nations-list").html(html);
    }


    jQuery(document).ready(function () {
      $(".map-search-input").on('keyup', function () {
        var searchString = $(this).val().toLowerCase();
        var cleanMarkers = filteredMarkers.slice(0);

        cleanMarkers = cleanMarkers.filter(function (value, index, arr) {
          return cleanMarkers[index].visible === true || cleanMarkers[index].filtered === true;
        });

        for (var i = 0; i < cleanMarkers.length; i++) {
          cleanMarkers[i].filtered = true;
          if (searchString.length === 0) {
            cleanMarkers[i].visibleSidebar = true;
            cleanMarkers[i].setVisible(true);
            continue;
          }
          if (cleanMarkers[i].visible || cleanMarkers[i].filtered) {
            cleanMarkers[i].visibleSidebar = false;
            cleanMarkers[i].setVisible(false);
            var markerSearchData = [];

            // search string for input, if you need to search more fields, just add them here
            markerSearchData =
              cleanMarkers[i].title.toLowerCase() + " " +
              cleanMarkers[i].address.toLowerCase();

            var found = searchSidebar(searchString, markerSearchData);
            if (found) {
              cleanMarkers[i].setVisible(true);
              cleanMarkers[i].visibleSidebar = true;
            }
          }
        }
        drawSidebar(cleanMarkers);
        markerCluster.setMap(null);
        initCluser(map, markers);
      });
    });

    function searchSidebar(searchString, findString) {
      return findString.includes(searchString);
    }


    initMap();


  }


  // ************************ end store locator ************************


  // ************************ modal video ************************

  $('.model-60__box--play').on('click', function (e) {
    e.preventDefault();
    $('.model-60__modal').removeClass('d-none');
    $('html').addClass('noscroll');
    var idvideo = $('.model-60__modal--video').attr('data-video');
    $('#md-video').append('<iframe src="https://www.youtube.com/embed/' + idvideo + '?autoplay=1&mute=1" frameborder="0"\n' +
      'allowfullscreen></iframe>')

  });
  $('#close-modal-video').add('.model-60__modal').on('click', function () {
    $('.model-60__modal').addClass('d-none');
    $('html').removeClass('noscroll');
    $('#md-video').empty();
  });

  // ************************ end modal video ************************


  // ************************ 3 images ************************

  //545 x 735
  $(window).resize(function () {
    resizeImg();

  });
  resizeImg();

  function resizeImg() {
    var w = $('.model-64__imgs').width();
    var h = $('.model-64__imgs').height();


    var ht = (w * 672) / 515
    $('.model-64__imgs').height(ht)

  }

  // ************************ end 3 images ************************

  $(document).ready(function () {

    $('html, body').animate({
      scrollTop: 0,
      scrollLeft: 0
    }, 500);

    if ($(window).width() > 767) {
      setTimeout(
        function () {
          equalizerCol('.equalizer-col', '.item');
        }, 300);

    }
    if ($(window).width() < 768) {
      $('.model-06__cta').html('<span class="icon-send"></span>');
    }

    $("#menu-mobile").mCustomScrollbar({
      axis: "y",
      theme: "dark-3",
    });

    var offset = $('.menu-styck').offset();
    $(window).bind('scroll', function () {

      if (offset) {
        var navHeight = offset.top;
        if ($(window).width() > 1399) {
          $(".level-1").removeClass('open-filter');
        }
        if ($(window).scrollTop() > navHeight) {
          $('.menu-styck').addClass('styck');
          if ($(window).width() > 991 && $('#filter-result').hasClass('result')) {
            removeResult();
            checkResult();
          }
        } else {
          $('.menu-styck').removeClass('styck');
          if ($(window).width() > 991) {
            addResult();
          }
        }
      }
    });


    var sections = $('body > div'),
      nav = $('.anchor-menu'),
      nav_height = nav.outerHeight();

    $(window).on('scroll', function () {
      var cur_pos = $(this).scrollTop();
      sections.each(function () {
        if ($(this).attr('id')) {
          var top = $(this).offset().top - nav_height,
            bottom = top + $(this).outerHeight();
          if (cur_pos >= top && cur_pos <= bottom) {
            nav.find('a').removeClass('active');
            nav.find('a[href="#' + $(this).attr('id') + '"]').addClass('active');
          }
        }
      });
    });

    nav.find('a').on('click', function () {
      var $el = $(this),
        id = $el.attr('href');

      if (id !== '#' && $(id).length) {
        $('html, body').animate({
          scrollTop: $(id).offset().top - nav_height
        }, 500);
      }

      //return false;
    });
    $(window).on('load', () => {
      const id = window.top.location.hash
      if (id !== '#' && $(id).length) {
        $('html, body').animate({
          scrollTop: $(id).offset().top - nav_height
        }, 500);
      }
      if ($(id).length && $(id).hasClass('t-section')) {
        openSection($(id))
      }
    })

    if ($('div').hasClass('container-stick')) {
      var containerStick = $('.container-stick').find('.menu-styck');
      var dropDownMenuStick = $('.container-stick').find('.second-menu-hmb-dropdown ')
      $('.container-stick').css({'width': $(window).width(), 'height': containerStick.height() + 4})
    }

    if ($('div').hasClass('container-stick-vert')) {
      var containerStick = $('.container-stick').find('.menu-vert');
      $('.container-stick-vert').css({'width': '64px', 'height': '100%'})
    }
    if ($('div').hasClass('two__text')) {
      var twoText = $('.two__text').offset();
      $('.logo-diesel').css('top', twoText.top)
    }


  });


  if ($('.gateways--slider').length) {
    const gatewaySlider = new Flickity('.gateways--slider', {cellAlign: 'left', groupCells: true})
  }


  // SCROLL
  $.fn.scrollView = function () {
    return this.each(function () {
      $('html, body').animate({
        scrollTop: $(this).offset().top - 80
      }, 1000, function () {
        //$('.off-canvas-wrap').foundation('offcanvas', 'hide', 'move-right');
      });
    });
  };
  $('.scrollTo').click(function (event) {
    event.preventDefault();
    var target = $(this).attr('href');

    $(target).scrollView();

  });

  // togglable sections feature
  let flickities = [];

  function flickityFix() {
    flickities.forEach(f=>{
      if(f){
        f.resize()
      }
    })
  }

  function openSection(element, callback = () => {
  }) {
    if (element) {
      if ($('.t-section--active').length) {
        $('.t-section--active .t-section-content').slideUp(() => {
          $('.t-section--active').removeClass('t-section--active')
          element.addClass('t-section--active');
          element.find('.t-section-content').slideDown(() => {
            if (element.attr('id')) {
              setTimeout(()=>{
                $('html, body').animate({
                  scrollTop: $(document).find(element).offset().top
                }, 500);
              },300)
              window.top.location.hash = element.attr('id')
              // if(element.find('.t-section-content').find('.t-section-slider').length){
              //   const f = Flickity.data(element.find('.t-section-content').find('.t-section-slider')[0])
              //   f.resize()
              // }
              console.log('open when active')
              flickityFix()
              callback()
            }
          })
        })
      } else {
        element.addClass('t-section--active');
        element.find('.t-section-content').slideDown(() => {
          if (element.attr('id')) {
            $('html, body').animate({
              scrollTop: $(document).find(element).offset().top
            }, 500);
            window.top.location.hash = element.attr('id')
            flickityFix()
            callback()
          }
        })
      }

    }
  }

  $('.t-section-open').on('click', (e) => {
    e.preventDefault();
    const parentSection = $(e.currentTarget).closest('.t-section')
    openSection(parentSection);
  })
  $('.t-section-close').on('click', (e) => {
    e.preventDefault();
    $('.t-section--active .t-section-content').slideUp(() => {
      $('.t-section').removeClass('t-section--active')
    })
  })

  $('.t-section-slider').each((i, e) => {
    const slider = new Flickity(e, {
      cellAlign: 'center',
      prevNextButtons: true,
      pageDots: true
    })
    flickities.push(slider)
    $(window).on('load', () => {
      slider.resize()
    })
  })

});


(function ($) {

// TOP VIDEO BOTTEGADARTE
  $(document).on("click", ".play-icon-2", function () {
    $(this).hide();
    var video = '<iframe id="teaser-2" width="560" height="315" src="https://www.youtube.com/embed/FP9oUbATbs8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
    $('#video-container').html(video);
  });

// MAGNIFIC POPUP
  $(document).ready(function () {

    // IMMAGINE SINGOLA
    // $('.popoup-image').magnificPopup({
    //   type: 'image',
    // });
    // GALLERY
    $('.popup-gallery').each(function () { // the containers for all your galleries
      // $(this).magnificPopup({
      //   delegate: 'a', // the selector for gallery item
      //   type: 'image',
      //   removalDelay: 300,
      //   mainClass: 'mfp-fade',
      //   gallery: {
      //     enabled: true
      //   },
      //   image: {
      //     titleSrc: 'title'
      //   }
      // });
    });
    // EMBED IFRAME
    // $('.popoup-issuu, .popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
    //   disableOn: 700,
    //   type: 'iframe',
    //   mainClass: 'mfp-fade',
    //   removalDelay: 160,
    //   preloader: false,
    //   fixedContentPos: false,
    //   iframe: {
    //     markup: '<div class="mfp-iframe-scaler">' +
    //       '<div class="mfp-close"></div>' +
    //       '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
    //       '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
    //
    //     patterns: {
    //       youtube: {
    //         index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).
    //
    //         id: 'v=', // String that splits URL in a two parts, second part should be %id%
    //         // Or null - full URL will be returned
    //         // Or a function that should return %id%, for example:
    //         // id: function(url) { return 'parsed id'; }
    //
    //         src: 'https://www.youtube.com/embed/%id%?rel=0&amp;controls=0&amp;showinfo=0&amp;autoplay=1' // URL that will be set as a source for iframe.
    //       },
    //       vimeo: {
    //         index: 'vimeo.com/',
    //         id: '/',
    //         src: '//player.vimeo.com/video/%id%?autoplay=1'
    //       },
    //       gmaps: {
    //         index: '//maps.google.',
    //         src: '%id%&output=embed'
    //       },
    //       issuu: {
    //         index: 'issuu.com/',
    //         //id: 'iargs=',
    //         src: '%id%'
    //       },
    //
    //       // you may add here more sources
    //
    //     },
    //
    //     srcAction: 'iframe_src', // Templating object key. First part defines CSS selector, second attribute. "iframe_src" means: find "iframe" and set attribute "src".
    //   }
    // });

  });

})($);
